<template>
    <div class="surface-card p-6 border-1 surface-border">
        <update-view-header
            :headline="$t('Create survey')"
            button-path="/surveys"
            :button-text="$t('Back to overview')"
        />

        <form v-on:submit.prevent="submitEntity" novalidate>
            <div class="field grid" v-for="field in fields" :key="field.key">
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ field.label }}
                </label>
                <div class="col">
                    <EntitySelect
                        v-model="editedEntity[field.key]"
                        :multiple="false"
                        type="company"
                        class="w-full"
                        v-if="field.key === 'company'"
                    />
                    <EntitySelect
                        v-model="editedEntity[field.key]"
                        :multiple="true"
                        type="functionalArea"
                        class="w-full"
                        ref="functionalAreas"
                        v-else-if="field.key === 'functionalAreas'"
                    />
                    <div class="p-inputgroup"
                         v-else-if="field.key === 'addFunctionalArea'"
                    >
                        <InputText
                            class="w-full"
                            v-model="editedEntity[field.key]"
                        />
                        <span class="p-inputgroup-addon" @click="addFunctionalArea">
                            <i class="pi pi-plus"></i>
                        </span>
                    </div>
                    <InputText
                        v-else
                        class="w-full"
                        type="text"
                        required
                        v-model="editedEntity[field.key]"
                    />
                    <small class="p-invalid block mt-2" v-for="message in editedEntityErrors[field.key]" :key="message">
                        {{ message }}
                    </small>
                </div>
            </div>

            <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

            <div class="text-center">
                <Button type="submit" :label="$t('Save')"></Button>
            </div>
        </form>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Button from "primevue/button";
    import Permissions from "@/components/form/Permissions";
    import EntitySelect from "@/components/form/EntitySelect";
    import UpdateView from "@/mixin/UpdateView";
    import Checkbox from "primevue/checkbox/Checkbox";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateViewHeader,
            EntitySelect,
            Permissions,
            InputText,
            Button,
            Checkbox
        },
        data() {
            return {

            }
        },
        computed: {
            fields: function() {
                return [
                    {
                        key: 'internName',
                        label: this.$t('Internal name'),
                    },
                    {
                        key: 'externalId',
                        label: this.$t('Extern Id'),
                    },
                    {
                        key: 'unit',
                        label: this.$t('Department'),
                    },
                    {
                        key: 'location',
                        label: this.$t('Location'),
                    },
                    {
                        key: 'company',
                        label: this.$t('Company'),
                    },
                    {
                        key: 'functionalAreas',
                        label: this.$t('Functional areas'),
                    },
                    {
                        key: 'addFunctionalArea',
                        label: this.$t('Add functional area'),
                    }
                ]
            }
        },
        methods: {
            addFunctionalArea() {
                this.$sendToApi(
                    'functional-areas',
                    {name: this.editedEntity['addFunctionalArea']},
                    (entity) => {
                        this.$refs['functionalAreas'][0].addOption(entity);
                        this.$refs['functionalAreas'][0].addInternValue(entity);
                        this.editedEntity['addFunctionalArea'] = null;
                    })
                ;
            },
            getApiEndpoint() {
                return 'surveys';
            },
            getCreationAction(entity) {
                return this.$router.push('/surveys');
            },
        }
    }
</script>
